<template>
    <div>
      <div>
        <label for="txtStart">Start:</label><br>
        <input type="number" id="txtStart"   ref="txtStart" value="1"><br>
        <label for="txtEnd">End No:</label><br>
        <input type="number" id="txtEnd" ref="txtEnd" value="1000"><br><br>
        <input type="button" value="Generate" @click="generate_data" >
      </div>

      <ReportViewer v-if="jsondata" v-bind:json_data = jsondata v-bind:reportfilename="filename" v-bind:report_url="reppath"></ReportViewer>
    </div>
</template>

<script>
import ReportViewer from "./components/ReportViewerByJson.vue";
export default {
  name: 'App',
  components: {
    ReportViewer,
  },
  data(){
    return {
      reppath: 'data.json',
      jsondata : null,
      filename: 'withdraw_slip01.mrt',
      viewer : null,
      designer : null,
      reportObject : {
        "ReportGuid": "75512e5df61a782f913f0e7c632c3d52",
        "ReportName": "Report",
        "ReportAlias": "Report",
        "ReportFile": "withdraw_slip01.mrt",
        "ReportCreated": "/Date(1719844486000+0530)/",
        "ReportChanged": "/Date(1719921356000+0530)/",
        "EngineVersion": "EngineV2",
        "CalculationMode": "Interpretation",
        "ReportUnit": "Millimeters",
        "PreviewSettings": 268435455,
        "Dictionary": {
          "DataSources": {
            "0": {
              "Ident": "StiDataTableSource",
              "Name": "root",
              "Alias": "root",
              "Columns": {
                "0": {
                  "Name": "id",
                  "Index": -1,
                  "NameInSource": "id",
                  "Alias": "id",
                  "Type": "System.String"
                }
              },
              "NameInSource": "data.root"
            }
          },
          "Databases": {
            "0": {
              "Ident": "StiJsonDatabase",
              "Name": "data",
              "Alias": "data"
            }
          }
        },
        "Pages": {
          "0": {
            "Ident": "StiPage",
            "Name": "Page1",
            "Guid": "5596908775178c03ecf0fbe7bd82600f",
            "Interaction": {
              "Ident": "StiInteraction"
            },
            "Border": ";;2;;;;;solid:Black",
            "Brush": "solid:",
            "Components": {
              "0": {
                "Ident": "StiDataBand",
                "Name": "Dataroot",
                "ClientRectangle": "0,4,210.1,102",
                "Interaction": {
                  "Ident": "StiBandInteraction"
                },
                "Border": ";;;;;;;solid:Black",
                "Brush": "solid:",
                "Components": {
                  "0": {
                    "Ident": "StiText",
                    "Name": "Dataroot_id",
                    "CanGrow": true,
                    "ClientRectangle": "0,93,210,8",
                    "Interaction": {
                      "Ident": "StiInteraction"
                    },
                    "Text": {
                      "Value": "{root.id}"
                    },
                    "HorAlignment": "Center",
                    "VertAlignment": "Bottom",
                    "Font": ";12;Bold;",
                    "Border": ";;;;;;;solid:Black",
                    "Brush": "solid:",
                    "TextBrush": "solid:Black",
                    "TextOptions": {
                      "WordWrap": true
                    }
                  }
                },
                "SkipFirst": false,
                "BreakIfLessThan": 0,
                "DataSourceName": "root"
              }
            },
            "PaperSize": "A4",
            "PageWidth": 210.1,
            "PageHeight": 324,
            "Watermark": {
              "TextBrush": "solid:50,0,0,0"
            },
            "Margins": {
              "Left": 0,
              "Right": 0,
              "Top": 0,
              "Bottom": 0
            }
          }
        }
      },
    }
  },
  created() {
    Stimulsoft.Base.StiLicense.Key = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHn0s4gy0Fr5YoUZ9V00Y0igCSFQzwEqYBh/N77k4f0fWXTHW5rqeBNLkaurJDenJ9o97TyqHs9HfvINK18Uwzsc/bG01Rq+x3H3Rf+g7AY92gvWmp7VA2Uxa30Q97f61siWz2dE5kdBVcCnSFzC6awE74JzDcJMj8OuxplqB1CYcpoPcOjKy1PiATlC3UsBaLEXsok1xxtRMQ283r282tkh8XQitsxtTczAJBxijuJNfziYhci2jResWXK51ygOOEbVAxmpflujkJ8oEVHkOA/CjX6bGx05pNZ6oSIu9H8deF94MyqIwcdeirCe60GbIQByQtLimfxbIZnO35X3fs/94av0ODfELqrQEpLrpU6FNeHttvlMc5UVrT4K+8lPbqR8Hq0PFWmFrbVIYSi7tAVFMMe2D1C59NWyLu3AkrD3No7YhLVh7LV0Tttr/8FrcZ8xirBPcMZCIGrRIesrHxOsZH2V8t/t0GXCnLLAWX+TNvdNXkB8cF2y9ZXf1enI064yE5dwMs2fQ0yOUG/xornE";
  },
  mounted() {
    const self = this;
    // self.createViewer();
    // self.setReport(self.$data.report);
  },
  methods:{
    createViewer() {
      const self = this;
      // Specify necessary options for the viewer
      const options = new Stimulsoft.Viewer.StiViewerOptions();
      options.height = "100%";
      options.appearance.scrollbarsMode = true;
      options.toolbar.showDesignButton = false;
      options.toolbar.printDestination = Stimulsoft.Viewer.StiPrintDestination.Direct;
      options.appearance.htmlRenderMode = Stimulsoft.Report.Export.StiHtmlExportMode.Table;

      // Create an instance of the viewer
      self.$data.viewer = new Stimulsoft.Viewer.StiViewer(options, "StiViewer", false);

      // Add the design button event
      self.$data.viewer.onDesignReport = function (e) {
        this.visible = false;
        if (designer == null) createDesigner();
        designer.visible = true;
        designer.report = e.report;
      };

      self.$data.viewer.renderHtml("viewerContent");
    },
    createDesigner() {
      const self = this;

      const options = new Stimulsoft.Designer.StiDesignerOptions();
      options.appearance.fullScreenMode = true;
      options.appearance.htmlRenderMode = Stimulsoft.Report.Export.StiHtmlExportMode.Table;

      // Create an instance of the designer
      self.$data.designer = new Stimulsoft.Designer.StiDesigner(options, "StiDesigner", false);

      // Add the exit menu item event
      self.$data.designer.onExit = function (e) {
        this.visible = false;
        viewer.visible = true;
      }

      self.$data.designer.renderHtml("designerContent");
    },
    setReport() {
      const self = this;
      // Forcibly show process indicator
      self.$data.viewer.showProcessIndicator();

      // Timeout need for immediate display loading report indicator
      setTimeout(function () {
        // Create a new report instance
        var report = new Stimulsoft.Report.StiReport();
        // Load reports from JSON object
        report.load(self.$data.reportObject);

        // Assign the report to the viewer
        self.$data.viewer.report = report;
      }, 50);
    },
    generate_data(){
      const self = this;

      const intS = parseInt(this.$refs.txtStart.value);
      const intN = intS + parseInt(this.$refs.txtEnd.value);

      try {
        let list = [];
        for (let i = intS; i < intN; i++) {

          let zerofilled = 'C ' + String(i).padStart(7, '0');
          let json = {"id": zerofilled}
          list.push(json);

          zerofilled = 'C ' + String(i + 1000).padStart(7, '0');
          json = {"id": zerofilled}
          list.push(json);

          zerofilled = 'C ' + String(i + 1000 + 1000).padStart(7, '0');
          json = {"id": zerofilled}
          list.push(json);
        }

        this.$data.jsondata = list;
      }catch (e) {
        alert(e);
      }

    },
  }
}
</script>

<style>

</style>
